import { useSearch, useSearchByShelfNums } from './useSearch';
import { useFilters } from './useFilters';
import { usePagination } from './usePagination';
import { useGetInventoryManagerListInventoryQuery } from 'graphql/__generated__/hooks';
import { ListInventory } from 'graphql/__generated__/schemas';
interface useInventoryEntriesResult {
  inventoryEntries?: ListInventory;
  preInventoryEntries?: ListInventory;
  isLoading: boolean;
  error?: string;
  refetchInventoryList: () => void;
}

export function useInventoryEntries(): useInventoryEntriesResult {
  const { skus } = useSearch();
  const { shelfNums } = useSearchByShelfNums();
  const { hub } = useFilters();
  const { pageIndex: pageNumber, pageSize } = usePagination();

  const { data, previousData, loading, error, refetch } = useGetInventoryManagerListInventoryQuery({
    variables: {
      input: {
        pageNumber,
        pageSize,
        hubSlug: hub,
        skus,
        shelfNums,
      },
    },
  });
  return {
    inventoryEntries: data?.getInventoryManagerListInventory,
    preInventoryEntries: previousData?.getInventoryManagerListInventory,
    isLoading: loading,
    error: error?.message,
    refetchInventoryList: refetch,
  };
}
