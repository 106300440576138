import { useState } from 'react';
import { useTranslation, useUpdateInventoryEntry } from 'hooks';
import { Spinner } from 'components/common';
import { Inventory } from 'graphql/__generated__/schemas';
import {
  DEFAULT_UPDATE_INVENTORY_REASON_OPTION,
  ReasonsDropdown,
} from 'components/ReasonsDropdown';
export interface UpdateInventoryFormProps {
  inventoryEntry: Inventory | null;
  onSuccess: () => void;
}

export function UpdateInventoryForm({ inventoryEntry, onSuccess }: UpdateInventoryFormProps) {
  const { t } = useTranslation();
  const { hubSlug, sku, quantity: currentQuantity } = inventoryEntry;
  const [amount, setAmount] = useState('0');
  const [reason, setReason] = useState(DEFAULT_UPDATE_INVENTORY_REASON_OPTION);

  const { isLoading, updateInventoryEntry } = useUpdateInventoryEntry({
    sku,
    hubId: hubSlug,
    onSuccess,
  });

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await updateInventoryEntry({
      amount: Number(amount),
      reason: reason.id,
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <form
        id="edit-inventory-form"
        data-testid="edit-inventory-form"
        className="flex flex-col gap-6 bg-flinkGray p-4 rounded-md text-start"
        onSubmit={onSubmit}
      >
        <div className="flex items-center">
          <div className="font-bold w-20">{t('attribute.hub-slug')}</div>
          <div className="flex-1">{hubSlug}</div>
        </div>

        <div className="flex items-center">
          <div className="font-bold w-20">{t('form-field.sku')}</div>
          <div className="flex-1">{sku}</div>
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <div className="flex items-center">
              <div className="font-bold w-20">{t('form-field.amount')}</div>

              <input
                type="number"
                className="flex-1 text-white h-8 p-2 bg-flinkGray-medium rounded-md border-none focus:outline-none"
                id="amount"
                name="amount"
                aria-label="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>

            <div className="flex">
              <div className="w-24" />
              <div className="text-xs">{t('form-field.amount.helper-text')}</div>
            </div>
          </div>

          <div className="flex items-center">
            <div className="font-bold w-20">{t('form-field.new-quantity')}</div>
            <div className="flex-1">
              <span className="p-2 !bg-success rounded-md text-flinkGray">
                {currentQuantity + Number(amount)}
              </span>
            </div>
          </div>
        </div>

        <div className="flex items-center">
          <div className="font-bold w-20">{t('form-field.reason')}</div>
          <ReasonsDropdown value={reason} onChange={setReason} />
        </div>
      </form>

      <button
        type="submit"
        form="edit-inventory-form"
        id="edit-inventory-form_submit"
        className="p-2 text-white font-bold rounded-md self-end bg-flinkPink disabled:cursor-not-allowed disabled:opacity-50"
        disabled={!amount || amount === '0' || isLoading}
      >
        {isLoading ? <Spinner size={5} /> : t('action.save')}
      </button>
    </div>
  );
}
