import { devtools } from 'zustand/middleware';
import { create } from 'zustand';
import { enableDevTool } from 'lib/constants/flag';
import { BulkUpdateStockStore, BulkUpdateStockStoreWithMethods } from './types';

const initialState: BulkUpdateStockStore = {
  selectedSKUsById: {},
  totalSelectedSKUs: 0,
  isSelectionLimitExceeded: false,
  isFormVisible: false,
};

const SELECTION_LIMIT = 100;

export const useBulkUpdateStock = create<BulkUpdateStockStoreWithMethods>()(
  devtools(
    (set) => ({
      ...initialState,
      setSelectedSKUsById: (selectedSKUsById) =>
        set((currentState) => ({
          ...currentState,
          selectedSKUsById,
          totalSelectedSKUs: Object.keys(selectedSKUsById).length,
          isSelectionLimitExceeded: Object.keys(selectedSKUsById).length > SELECTION_LIMIT,
        })),
      removeSelectedSKUById: (selectedSKUs: string[]) => {
        set((currentState) => {
          const { selectedSKUsById, ...rest } = currentState;

          const filteredSelectedSKUsById = Object.fromEntries(
            Object.entries(selectedSKUsById).filter(([, value]) => selectedSKUs.includes(value))
          );
          const totalSelectedSKUs = Object.keys(filteredSelectedSKUsById).length;
          return {
            ...rest,
            selectedSKUsById: filteredSelectedSKUsById,
            totalSelectedSKUs,
            isSelectionLimitExceeded: totalSelectedSKUs > SELECTION_LIMIT,
          };
        });
      },
      setIsFormVisible: (isFormVisible) => {
        set((currentState) => ({ ...currentState, isFormVisible }));
      },
      reset() {
        set(initialState);
      },
    }),
    {
      name: 'useBulkUpdateStock',
      enabled: enableDevTool,
    }
  )
);
