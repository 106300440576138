import { useToggle } from 'usehooks-ts';
import { HubResetModal } from './HubResetModal';
import { useFilters, useTranslation } from 'hooks';
import { Icon } from '@hubportal/components';
import { useGetHubInventoryResetStatusQuery } from 'graphql/__generated__/hooks';
import { getFormattedDateAndTime } from 'lib/helpers';
import { useInventoryResetStatus } from 'stores';

export const HubReset = () => {
  const { t } = useTranslation();
  const [open, toggle] = useToggle();
  const { setInventoryResetStatus } = useInventoryResetStatus();
  const { hub } = useFilters();

  const { data } = useGetHubInventoryResetStatusQuery({
    variables: {
      input: { hubId: hub },
    },
    onCompleted(data) {
      const status = data.getHubInventoryResetStatus.status;
      setInventoryResetStatus(status);
    },
    onError() {
      setInventoryResetStatus(null);
    },
  });
  const status = data?.getHubInventoryResetStatus?.status;

  if (status) {
    const updatedAt = data?.getHubInventoryResetStatus?.updatedAt;
    const date = getFormattedDateAndTime(updatedAt);

    return (
      <div className="flex flex-col text-white text-sm gap-1">
        <div className="flex items-center gap-2">
          <Icon type="caution" size="small" />
          {t('hub-reset.label', { hub })}
          <span className="font-bold underline underline-offset-4">
            {t('hub-reset.status', { status, date })}
          </span>
        </div>
        {t('hub-reset.warning')}
      </div>
    );
  }
  return (
    <>
      <button
        className="flex gap-2 items-center text-sm text-flinkGray-light hover:text-flinkPink"
        onClick={toggle}
      >
        <Icon type="closed" size="medium" />
        {t('action.hub-reset')}
      </button>
      <HubResetModal isOpen={open} onClose={toggle} />
    </>
  );
};
