import { useFilters, useTranslation } from 'hooks';
import { Checkbox, Modal, Spinner } from 'components/common';
import { useToggle } from 'usehooks-ts';
import { ChangeEvent, useState } from 'react';
import {
  GetHubInventoryResetStatusDocument,
  useInventoryManagerHubResetMutation,
} from 'graphql/__generated__/hooks';
import { notifySuccess, notifyError } from 'lib/toast';
import sdk from '@hubportal/sdk';

type HubResetModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const HubResetModal = ({ isOpen, onClose }: HubResetModalProps) => {
  const { t } = useTranslation();
  const [isChecked, toggle, setIsChecked] = useToggle();
  const [isValid, setIsValid] = useState(false);
  const { hub: hubSlug } = useFilters();
  const actorId = sdk.getUserProfile().email;
  const handleOnClose = () => {
    setIsValid(false);
    setIsChecked(false);
    onClose();
  };
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value;
    const isValid = hubSlug === inputValue;
    setIsValid(isValid);
  };

  const [inventoryManagerHubReset, { loading: isLoading }] = useInventoryManagerHubResetMutation({
    variables: {
      input: { hubSlug, actorId },
    },
    onCompleted() {
      notifySuccess(t('notify-success.hub-reset'));
      handleOnClose();
    },
    onError(error) {
      notifyError(error.message);
    },
    refetchQueries: [GetHubInventoryResetStatusDocument, 'GetHubInventoryResetStatus'],
  });

  const handleHubReset = () => inventoryManagerHubReset();

  return (
    <Modal
      isOpen={isOpen}
      onClickOutside={handleOnClose}
      customClass="w-2/5 text-start"
      title={t('modal.hub-reset.title')}
    >
      <>
        <p className="text-white">{t('modal.hub-reset.sub-title')}</p>
        <div className="flex items-center gap-4 text-white text-lg py-4">
          <label htmlFor="hub-slug" className="font-bold">
            {hubSlug}
          </label>
          <input
            type="text"
            id="hub-slug"
            name="hub-slug"
            className={`${
              isValid
                ? 'border-transparent focus:border-transparent'
                : 'border-pink focus:border-pink'
            } bg-flinkGray border border-solid outline-none ring-transparent focus:ring-transparent px-3 w-full max-w-xs h-12 rounded-lg`}
            onChange={handleOnChange}
            onPaste={(e: any) => {
              e.preventDefault();
              return false;
            }}
            onCopy={(e: any) => {
              e.preventDefault();
              return false;
            }}
            aria-label="hub-slug-input"
          />
        </div>
        <Checkbox ariaLabel="note" onChange={toggle}>
          <p className="text-white">{t('modal.hub-reset.confirmation')}</p>
        </Checkbox>
        <div className="flex justify-between gap-4 pt-4">
          <button
            className="text-white bg-flinkGray-dark w-full leading-none p-4 rounded text-lg font-extrabold h-13"
            onClick={handleOnClose}
          >
            {t('action.cancel')}
          </button>
          <button
            className="flex items-center justify-center text-white bg-flinkPink w-full leading-none p-4 rounded text-lg font-extrabold h-13 disabled:opacity-50"
            onClick={handleHubReset}
            disabled={!isChecked || !isValid}
          >
            {isLoading ? <Spinner size={5} /> : t('action.reset')}
          </button>
        </div>
      </>
    </Modal>
  );
};
