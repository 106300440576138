import { DEFAULT_SELECTED_HUB_SLUG, PAGINATION } from 'lib/constants';
import { useURLQueryParams } from './useURLQueryParams';
import { useEffectOnce } from 'usehooks-ts';

export interface useFiltersResult {
  hub: string;
  updateHub: (hub: string) => void;
}

export function useFilters(): useFiltersResult {
  const { queryParams, updateValues } = useURLQueryParams();

  const hub = queryParams.hub;

  // force `hub` URL query param to be present in the URL initially
  useEffectOnce(() => {
    const hub = queryParams.hub || DEFAULT_SELECTED_HUB_SLUG;
    const pageIndex =
      queryParams.pageIndex === PAGINATION.FIRST_PAGE_NUMBER ? undefined : queryParams.pageIndex;

    updateValues({ hub, pageIndex });
  });

  const updateHub = (hub: string) => updateValues({ hub });

  return { hub, updateHub };
}
