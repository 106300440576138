import useUrlState from '@ahooksjs/use-url-state';
import { PAGINATION } from 'lib/constants';
import { PaginationControls, PaginationValues } from 'types';
import { useURLQueryParams } from './useURLQueryParams';

type PaginationQueryParams = ReturnType<typeof useUrlState<PaginationValues>>[0];

const getPaginationValues = (pagination?: PaginationQueryParams): PaginationValues => {
  return {
    pageIndex: Number(pagination?.pageIndex) || PAGINATION.FIRST_PAGE_NUMBER,
    pageSize: Number(pagination?.pageSize) || PAGINATION.DEFAULT_PAGE_SIZE,
  };
};

export function usePagination(): PaginationControls {
  const { queryParams, updateValues } = useURLQueryParams();
  const pagination = getPaginationValues(queryParams);

  const setPage = (pageIndex: number) => {
    if (pageIndex > PAGINATION.FIRST_PAGE_NUMBER) {
      updateValues({ pageIndex });
    } else {
      updateValues({ pageIndex: undefined });
    }
  };

  const setPageSize = (pageSize: number) => {
    if (
      pageSize !== PAGINATION.DEFAULT_PAGE_SIZE ||
      PAGINATION.ALLOWED_PAGE_SIZES.includes(pageSize)
    ) {
      updateValues({ pageSize });
    } else {
      updateValues({ pageSize: undefined });
    }
  };

  return { ...pagination, setPage, setPageSize };
}
