import { useInventoryEntries } from 'hooks';
import { Searchbar } from 'components/Searchbar';
import { HubsFilter } from 'components/HubsFilter';
import { InventoryEntries } from 'components/InventoryEntries';
import { Spinner } from 'components/common';
import { BulkUpdateInventory } from 'components/UpdateInventory';
import { canBulkUpdateInventoryLevels } from 'lib/appPermissions';
import { SearchByShelfNumsBar } from 'components/SearchByShelfNumsBar';

const FilterSection = () => {
  return (
    <div className="flex flex-col">
      <div className="flex gap-8 py-4">
        <div className="w-68 z-20">
          <HubsFilter />
        </div>
        <Searchbar />
        <SearchByShelfNumsBar />
      </div>
      {canBulkUpdateInventoryLevels() && <BulkUpdateInventory />}
    </div>
  );
};

const InventoryListSection = () => {
  const { inventoryEntries, preInventoryEntries, isLoading, error, refetchInventoryList } =
    useInventoryEntries();

  if (error) {
    return (
      <div className="h-4/5 flex justify-center items-center text-white text-2xl font-bold">
        {error}
      </div>
    );
  }
  const inventoryEntry = isLoading ? preInventoryEntries : inventoryEntries;

  return (
    <>
      {isLoading && (
        <div className="absolute inset-0 w-screen h-screen flex justify-center items-center z-50">
          <Spinner size={20} />
        </div>
      )}
      {inventoryEntry && (
        <InventoryEntries data={inventoryEntry} refetchInventoryList={refetchInventoryList} />
      )}
    </>
  );
};

export function InventoryEntriesPage() {
  return (
    <div className="px-10 h-[calc(100vh-81px)]">
      <FilterSection />
      <InventoryListSection />
    </div>
  );
}
