import React, { useMemo } from 'react';

import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import SDK from '@hubportal/sdk';

type CustomApolloProviderProps = {
  children: React.ReactNode;
};

function generateApolloClient() {
  const authLink = setContext(async (_, { headers }) => {
    const token = await SDK.getAuthClient().getTokenSilently({
      authorizationParams: {
        audience: process.env.BFF_AUDIENCE,
      },
    });

    if (!token) {
      throw new Error('Authorization missing');
    }

    return {
      headers: {
        ...headers,
        authorization: token ? 'Bearer ' + token : null,
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) => {
        SDK.reportError(
          new Error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
          '@flapp/inventory-management',
          {
            message,
            locations,
            path,
          }
        );
      });
    if (networkError) SDK.reportError(networkError, '@flapp/inventory-management');
  });

  const httpLink = new HttpLink({
    uri: process.env.BFF_URL,
  });

  return new ApolloClient({
    link: from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache(),
  });
}

export function CustomApolloProvider({ children }: CustomApolloProviderProps) {
  const client = useMemo(() => {
    return generateApolloClient();
  }, []);
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
