import { TagInput as SemiDesignTagInput } from '@douyinfe/semi-ui';
import { type TagInputProps as SemiDesignTagInputProps } from '@douyinfe/semi-ui/lib/es/tagInput';
import './TagInput.scss';

export interface TagInputProps extends SemiDesignTagInputProps {
  value: string[];
  onChange: (value: string[]) => void;
  allowDuplicates?: boolean;
  ariaLabel: string;
  className?: string;
}

export function TagInput({
  value,
  onChange,
  allowDuplicates = false,
  ariaLabel,
  className,
  maxTagCount,
  ...overriddenProps
}: TagInputProps) {
  return (
    <SemiDesignTagInput
      showClear
      showRestTagsPopover
      addOnBlur
      className={`border-0 px-3 py-[11px] w-full bg-flinkGray text-white placeholder-flinkGray-light rounded-md cursor-pointer ${className}`}
      separator={['-', ',', ' ']}
      allowDuplicates={allowDuplicates}
      aria-label={ariaLabel}
      value={value}
      onChange={onChange}
      maxTagCount={maxTagCount}
      {...overriddenProps}
    />
  );
}
