import { devtools } from 'zustand/middleware';
import { create } from 'zustand';
import { isDevelopmentEnv, isStagingEnv } from 'lib/constants/appConfig';
import { Inventory } from 'graphql/__generated__/schemas';

type RowAction = 'update' | 'sync';

type Store = {
  action: null | RowAction;
  row: null | Inventory;
  onSelectAction: (selection: { row: Inventory; action: RowAction }) => void;
  onActionCompleted: () => void;
};

export const useTableRowActions = create<Store>()(
  devtools(
    (set) => ({
      action: null,
      row: null,
      onSelectAction: ({ action, row }) => set({ action, row }),
      onActionCompleted: () => set({ action: null, row: null }),
    }),
    {
      name: 'useTableRowActions',
      enabled: isDevelopmentEnv || isStagingEnv,
    }
  )
);
