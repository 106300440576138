import { Select, SelectItem } from '@hubportal/components';
import { useTranslation } from 'hooks';
import { SelectOption, getReasonsDropdownOptions } from './helpers';

export interface ReasonsDropdownProps {
  value: SelectOption;
  onChange: (value: SelectOption) => void;
}

export function ReasonsDropdown({ value, onChange }: ReasonsDropdownProps) {
  const { t } = useTranslation();
  const options = getReasonsDropdownOptions();
  return (
    <Select
      searchable
      placeholder={t('placeholder.reason-selection')}
      value={{ id: value.id, label: t(value.label) }}
      onChange={onChange}
    >
      {options.map(({ id, label }) => (
        <SelectItem key={id} id={id} label={label}>
          {t(label)}
        </SelectItem>
      ))}
    </Select>
  );
}
