import { useTranslation } from 'hooks';
import { Sidebar } from 'components/common';
import { BulkUpdateInventoryForm } from './BulkUpdateInventoryForm';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { useBulkUpdateStock } from 'stores';

export function BulkUpdateInventory() {
  const { t } = useTranslation();
  const { isFormVisible, setIsFormVisible, totalSelectedSKUs, isSelectionLimitExceeded } =
    useBulkUpdateStock();

  return (
    <div>
      <div className="flex gap-2 items-center">
        <button
          className="bg-flinkPink text-white h-full rounded-md px-4 py-2 text-sm disabled:opacity-50 w-max"
          aria-label="update-inventory-levels"
          disabled={!totalSelectedSKUs || isSelectionLimitExceeded}
          onClick={() => setIsFormVisible(true)}
        >
          {t('action.update-inventory')}
        </button>
        {totalSelectedSKUs ? (
          <span className={`${isSelectionLimitExceeded ? 'text-red' : 'text-white'}`}>
            {t('info.selected-sku', { count: totalSelectedSKUs })}
          </span>
        ) : null}
      </div>
      <Sidebar
        visible={isFormVisible}
        title={t('sidebar.update-inventory.title')}
        onClose={() => setIsFormVisible(false)}
        size="medium"
      >
        <ErrorBoundary>
          <BulkUpdateInventoryForm />
        </ErrorBoundary>
      </Sidebar>
    </div>
  );
}
