import { SideSheet } from '@douyinfe/semi-ui';
import type { SideSheetReactProps } from '@douyinfe/semi-ui/lib/es/sideSheet';
import './Sidebar.scss';

export type SidebarProps = React.PropsWithChildren<{
  title: React.ReactNode;
  visible: boolean;
  size?: SideSheetReactProps['size'];
  onClose: () => void;
}>;

export function Sidebar({ visible, title, size = 'small', children, onClose }: SidebarProps) {
  return (
    <SideSheet closable={false} title={title} visible={visible} size={size} onCancel={onClose}>
      {children}
    </SideSheet>
  );
}
