import { format, utcToZonedTime } from 'date-fns-tz';
import { PaginationValues } from 'types';
import { PAGINATION } from './constants';

export const getFormattedDateAndTime = (value?: string | Date, pattern?: string) => {
  const formatPattern = pattern || 'dd.MM.yyyy HH:mm';
  if (!value) return '';
  const timeZone = 'CET';
  const date = new Date(value);
  const zonedDate = utcToZonedTime(date, timeZone);
  return format(zonedDate, formatPattern, { timeZone });
};

export const toArray = (queryParam: string | string[]): string[] =>
  Array.isArray(queryParam) ? queryParam : [queryParam];

export const isPositiveInteger = (value: string) => /^\d+$/.test(value);
export const isValidSKU = (value: string) => /^[\d]{8,9}$/.test(value);
export const isValidShelfNum = (value: string) => /^\d{3}[A-Z]$/.test(value);

export const getPaginationValues = (pagination?: Partial<PaginationValues>): PaginationValues => {
  return {
    pageIndex: Number(pagination?.pageIndex) || PAGINATION.FIRST_PAGE_NUMBER,
    pageSize: Number(pagination?.pageSize) || PAGINATION.DEFAULT_PAGE_SIZE,
  };
};
