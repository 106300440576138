import { useState } from 'react';
import { useFilters, useSearch, useTranslation } from 'hooks';
import { Spinner, TagInput } from 'components/common';
import {
  DEFAULT_UPDATE_INVENTORY_REASON_OPTION,
  ReasonsDropdown,
} from 'components/ReasonsDropdown';
import { useBulkUpdateStock } from 'stores';
import { useInventoryManagerBulkUpdateStockLevelMutation } from 'graphql/__generated__/hooks';
import sdk from '@hubportal/sdk';
import { notifyError, notifySuccess } from 'lib/toast';

const hideInputFieldClasses = '[&_.semi-tagInput-wrapper-input]:hidden';

export function BulkUpdateInventoryForm() {
  const { t } = useTranslation();
  const { hub: hubId } = useFilters();
  const { updateSKUs } = useSearch();
  const {
    selectedSKUsById,
    removeSelectedSKUById,
    totalSelectedSKUs,
    reset,
    isSelectionLimitExceeded,
  } = useBulkUpdateStock();
  const [quantity, setQuantity] = useState(0);
  const [reason, setReason] = useState(DEFAULT_UPDATE_INVENTORY_REASON_OPTION);
  const skus = Object.values(selectedSKUsById);
  const [inventoryManagerBulkUpdateStockLevelMutation, { loading: isLoading }] =
    useInventoryManagerBulkUpdateStockLevelMutation({
      variables: {
        input: {
          hubId,
          body: {
            actor: { id: sdk.getUserProfile().email },
            quantity,
            reason: reason.id,
            skus,
          },
        },
      },
      onCompleted() {
        notifySuccess(t('notify-success.bulk-update-inventory'));
        updateSKUs(skus);
        reset();
      },
      onError(error) {
        notifyError(error.message);
      },
    });

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    inventoryManagerBulkUpdateStockLevelMutation();
  };

  return (
    <div className="flex flex-col gap-2">
      <form
        id="edit-inventory-form"
        className="flex flex-col gap-6 bg-flinkGray p-4 rounded-md text-start"
        onSubmit={onSubmit}
      >
        <div className="flex items-center">
          <div className="font-bold w-20">{t('attribute.hub-slug')}</div>
          <div className="flex-1">{hubId}</div>
        </div>

        <div className="flex items-center">
          <div className="font-bold w-20">{t('form-field.skus')}</div>
          <div className="flex-1">
            <TagInput
              className={`w-p-0 py-2 bg-flinkGray-medium ${hideInputFieldClasses}`}
              ariaLabel="skus-to-update"
              placeholder={t('placeholder.bulk-update-skus')}
              maxLength={9}
              value={skus}
              onChange={removeSelectedSKUById}
            />
          </div>
        </div>

        <div className="flex flex-col gap-1">
          <div className="flex items-center">
            <div className="font-bold w-20">{t('form-field.new-quantity')}</div>

            <input
              type="number"
              className="flex-1 text-white h-8 p-2 bg-flinkGray-medium rounded-md border-none focus:outline-none"
              id="quantity"
              name="quantity"
              aria-label="quantity"
              value={quantity}
              onChange={(e) => setQuantity(parseInt(e.target.value))}
            />
          </div>

          <div className="flex">
            <div className="w-20" />
            <div className="text-xs">{t('form-field.new-quantity.helper-text')}</div>
          </div>
        </div>

        <div className="flex items-center">
          <div className="font-bold w-20">{t('form-field.reason')}</div>
          <ReasonsDropdown value={reason} onChange={setReason} />
        </div>
      </form>

      <button
        type="submit"
        form="edit-inventory-form"
        id="edit-inventory-form_submit"
        className="w-32 flex justify-center items-center mb-2 p-2 text-white font-bold rounded-md self-end bg-flinkPink disabled:cursor-not-allowed disabled:opacity-50"
        disabled={!totalSelectedSKUs || isLoading || isSelectionLimitExceeded}
      >
        {isLoading ? <Spinner size={5} /> : t('action.save')}
      </button>
    </div>
  );
}
