import sdk from '@hubportal/sdk';
import { PERMISSIONS } from './constants/permissions';
import { isProductionEnv } from './constants/appConfig';

export const canSelectTableRows = () => !isProductionEnv;

export const canUpdateInventoryLevels = () =>
  sdk.getUserProfile().permissions.includes(PERMISSIONS.UPDATE_INVENTORY_LEVELS);

export const canBulkUpdateInventoryLevels = () =>
  !isProductionEnv &&
  sdk.getUserProfile().permissions.includes(PERMISSIONS.UPDATE_INVENTORY_LEVELS);

export const canInvalidateBundles = () =>
  sdk.getUserProfile().permissions.includes(PERMISSIONS.INVALIDATE_BUNDLES);

export const canHubInventoryReset = () =>
  sdk.getUserProfile().permissions.includes(PERMISSIONS.UPDATE_INVENTORY_LEVELS);
