import { useNavigate } from 'react-router-dom';
import { HOMEPAGE_PATH } from 'lib/constants/routes';

export function NotFoundPage() {
  const navigate = useNavigate();
  const navigateToInventoryManagementPage = () => navigate(HOMEPAGE_PATH);

  return (
    <div id="error-page" className="h-4/5 flex flex-col justify-center items-center text-white">
      <h3 className="font-bold text-lg">Invalid URL</h3>

      <p className="mt-4">It seems you have navigated to a wrong page</p>

      <button
        className="bg-transparent self-center text-flinkPink border border-flinkPink-medium mt-12 py-2 px-4 rounded"
        onClick={navigateToInventoryManagementPage}
      >
        Go back to Inventory Management page
      </button>
    </div>
  );
}
