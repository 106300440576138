import { V1InventoryChangeReason } from 'graphql/__generated__/schemas';
import { enableNewReason } from './flag';

export const PAGINATION = {
  FIRST_PAGE_NUMBER: 1,
  DEFAULT_PAGE_SIZE: 10,
  ALLOWED_PAGE_SIZES: [10, 20, 30, 50, 100],
};

export const DEFAULT_SELECTED_HUB_SLUG = 'de_ber_mit1';

export const DEFAULT_UPDATE_INVENTORY_REASON =
  V1InventoryChangeReason.inventory_change_reason_correction;

const OLD_REASONS = {
  [V1InventoryChangeReason.inventory_change_reason_correction]: 'form-field.reason.correction',
  [V1InventoryChangeReason.inventory_change_reason_outbound_product_expired]:
    'form-field.reason.outbound-expired',
  [V1InventoryChangeReason.inventory_change_reason_outbound_product_damaged]:
    'form-field.reason.outbound-damaged',
  [V1InventoryChangeReason.inventory_change_reason_outbound_too_good_to_go]:
    'form-field.reason.too-good-to-go',
  [V1InventoryChangeReason.inventory_change_reason_inbound_goods_received]:
    'form-field.reason.inbound-goods-received',
} as const;

const NEW_REASONS = {
  [V1InventoryChangeReason.inventory_change_reason_correction]: 'form-field.reason.correction',
  [V1InventoryChangeReason.inventory_change_reason_outbound_product_expired]:
    'form-field.reason.outbound-expired',
  [V1InventoryChangeReason.inventory_change_reason_outbound_product_damaged]:
    'form-field.reason.outbound-damaged',
  [V1InventoryChangeReason.inventory_change_reason_inbound_goods_received]:
    'form-field.reason.inbound-goods-received',
  [V1InventoryChangeReason.inventory_change_reason_outbound_stock_transfer]:
    'form-field.reason.outbound_stock_transfer',
  [V1InventoryChangeReason.inventory_change_reason_inbound_stock_transfer]:
    'form-field.reason.inbound_stock_transfer',
  [V1InventoryChangeReason.inventory_change_reason_outbound_equipment_failure]:
    'form-field.reason.outbound_equipment_failure',
  [V1InventoryChangeReason.inventory_change_reason_outbound_hub_closure]:
    'form-field.reason.outbound_hub_closure',
} as const;

export const REASONS = enableNewReason ? NEW_REASONS : OLD_REASONS;
