export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type Actor = {
  id: Scalars['String']['input'];
};

export type BulkUpdateStockLevelInput = {
  body: BulkUpdateStockLevelInputBody;
  hubId: Scalars['String']['input'];
};

export type BulkUpdateStockLevelInputBody = {
  actor: Actor;
  quantity: Scalars['Int']['input'];
  reason: V1InventoryChangeReason;
  skus: Array<Scalars['String']['input']>;
};

export type BulkUpdateStockLevelResponse = {
  __typename?: 'BulkUpdateStockLevelResponse';
  success: Scalars['Boolean']['output'];
};

export type GetHubInventoryResetStatusInput = {
  hubId: Scalars['String']['input'];
};

export type GetHubInventoryResetStatusResponse = {
  __typename?: 'GetHubInventoryResetStatusResponse';
  status: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type GetHubStockChangeLogsInput = {
  from?: InputMaybe<Scalars['String']['input']>;
  hubSlug: Scalars['String']['input'];
  invDiffGt?: InputMaybe<Scalars['Int']['input']>;
  offset: Scalars['String']['input'];
  queryString?: InputMaybe<Scalars['String']['input']>;
  reasons?: InputMaybe<Array<V1InventoryChangeReason>>;
  sku?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type GetHubStockChangeLogsResponse = {
  __typename?: 'GetHubStockChangeLogsResponse';
  logs: Array<HubStockChangeLogs>;
};

export type GetListInventoryInput = {
  hubSlug: Scalars['String']['input'];
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  shelfNums?: InputMaybe<Array<Scalars['String']['input']>>;
  skus?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type HubResetInput = {
  actorId: Scalars['String']['input'];
  hubSlug: Scalars['String']['input'];
};

export type HubResetResponse = {
  __typename?: 'HubResetResponse';
  status: Scalars['String']['output'];
};

export type HubStockChangeLogs = {
  __typename?: 'HubStockChangeLogs';
  createdAt: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  quantityFrom: Scalars['Int']['output'];
  quantityTo: Scalars['Int']['output'];
  reason: V1InventoryChangeReason;
  sku: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type HubSummary = {
  __typename?: 'HubSummary';
  slug: Scalars['String']['output'];
};

export type Inventory = {
  __typename?: 'Inventory';
  hubSlug: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isBundle?: Maybe<Scalars['Boolean']['output']>;
  productName: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  shelfNumber: Scalars['String']['output'];
  sku: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type InventoryManagerUpdateStockLevelInput = {
  body: UpdateStockLevelInputBody;
  hubId: Scalars['String']['input'];
  sku: Scalars['String']['input'];
};

export type InventoryManagerUpdateStockLevelResponse = {
  __typename?: 'InventoryManagerUpdateStockLevelResponse';
  inventory: V1ProductInventory;
};

export type ListInventory = {
  __typename?: 'ListInventory';
  inventories: Array<Inventory>;
  pagination: Pagination;
};

export type Mutation = {
  __typename?: 'Mutation';
  inventoryManagerBulkUpdateStockLevel: BulkUpdateStockLevelResponse;
  inventoryManagerHubReset: HubResetResponse;
  inventoryManagerUpdateStockLevel: InventoryManagerUpdateStockLevelResponse;
  updateBundleCache: UpdateBundleCacheResponse;
};


export type MutationInventoryManagerBulkUpdateStockLevelArgs = {
  input: BulkUpdateStockLevelInput;
};


export type MutationInventoryManagerHubResetArgs = {
  input: HubResetInput;
};


export type MutationInventoryManagerUpdateStockLevelArgs = {
  input: InventoryManagerUpdateStockLevelInput;
};


export type MutationUpdateBundleCacheArgs = {
  input: UpdateBundleCacheInput;
};

export type Pagination = {
  __typename?: 'Pagination';
  pageNumber: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  getAllHubs: Array<HubSummary>;
  getHubInventoryResetStatus: GetHubInventoryResetStatusResponse;
  getHubStockChangeLogs: GetHubStockChangeLogsResponse;
  getInventoryManagerListInventory: ListInventory;
};


export type QueryGetHubInventoryResetStatusArgs = {
  input: GetHubInventoryResetStatusInput;
};


export type QueryGetHubStockChangeLogsArgs = {
  input: GetHubStockChangeLogsInput;
};


export type QueryGetInventoryManagerListInventoryArgs = {
  input: GetListInventoryInput;
};

export type UpdateBundleCacheInput = {
  hubSlug: Scalars['String']['input'];
  sku: Scalars['String']['input'];
};

export type UpdateBundleCacheResponse = {
  __typename?: 'UpdateBundleCacheResponse';
  status: Scalars['String']['output'];
};

export type UpdateStockLevelInputBody = {
  actor: Actor;
  amount: Scalars['Int']['input'];
  reason: V1InventoryChangeReason;
};

export enum V1InventoryChangeReason {
  inventory_change_reason_correction = 'INVENTORY_CHANGE_REASON_CORRECTION',
  inventory_change_reason_eoy_inventory_check = 'INVENTORY_CHANGE_REASON_EOY_INVENTORY_CHECK',
  inventory_change_reason_eoy_inventory_recount = 'INVENTORY_CHANGE_REASON_EOY_INVENTORY_RECOUNT',
  inventory_change_reason_expensive_check = 'INVENTORY_CHANGE_REASON_EXPENSIVE_CHECK',
  inventory_change_reason_inbound_bulk = 'INVENTORY_CHANGE_REASON_INBOUND_BULK',
  inventory_change_reason_inbound_goods_received = 'INVENTORY_CHANGE_REASON_INBOUND_GOODS_RECEIVED',
  inventory_change_reason_inbound_stock_transfer = 'INVENTORY_CHANGE_REASON_INBOUND_STOCK_TRANSFER',
  inventory_change_reason_low_stock_check = 'INVENTORY_CHANGE_REASON_LOW_STOCK_CHECK',
  inventory_change_reason_order_cancelled = 'INVENTORY_CHANGE_REASON_ORDER_CANCELLED',
  inventory_change_reason_order_created = 'INVENTORY_CHANGE_REASON_ORDER_CREATED',
  inventory_change_reason_outbound_delivery_product_damaged = 'INVENTORY_CHANGE_REASON_OUTBOUND_DELIVERY_PRODUCT_DAMAGED',
  inventory_change_reason_outbound_delivery_product_expired = 'INVENTORY_CHANGE_REASON_OUTBOUND_DELIVERY_PRODUCT_EXPIRED',
  inventory_change_reason_outbound_equipment_failure = 'INVENTORY_CHANGE_REASON_OUTBOUND_EQUIPMENT_FAILURE',
  inventory_change_reason_outbound_hub_closure = 'INVENTORY_CHANGE_REASON_OUTBOUND_HUB_CLOSURE',
  inventory_change_reason_outbound_product_damaged = 'INVENTORY_CHANGE_REASON_OUTBOUND_PRODUCT_DAMAGED',
  inventory_change_reason_outbound_product_expired = 'INVENTORY_CHANGE_REASON_OUTBOUND_PRODUCT_EXPIRED',
  inventory_change_reason_outbound_stock_transfer = 'INVENTORY_CHANGE_REASON_OUTBOUND_STOCK_TRANSFER',
  inventory_change_reason_outbound_too_good_to_go = 'INVENTORY_CHANGE_REASON_OUTBOUND_TOO_GOOD_TO_GO',
  inventory_change_reason_out_of_stock_check = 'INVENTORY_CHANGE_REASON_OUT_OF_STOCK_CHECK',
  inventory_change_reason_product_recall = 'INVENTORY_CHANGE_REASON_PRODUCT_RECALL',
  inventory_change_reason_rolling_inventory_check = 'INVENTORY_CHANGE_REASON_ROLLING_INVENTORY_CHECK',
  inventory_change_reason_unspecified = 'INVENTORY_CHANGE_REASON_UNSPECIFIED'
}

export type V1ProductInventory = {
  __typename?: 'V1ProductInventory';
  availableQuantity: Scalars['Int']['output'];
  hubId: Scalars['String']['output'];
  lastModifiedAt: Scalars['String']['output'];
  quantityOnStock: Scalars['Int']['output'];
  shelfNumber: Scalars['String']['output'];
  sku: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};
