import { useState, useEffect } from 'react';
const useKeyPress = (targetKey) => {
    const [keyPressed, setKeyPressed] = useState(false);
    useEffect(() => {
        function downHandler({ key }) {
            if (key === targetKey) {
                setKeyPressed(true);
            }
        }
        const upHandler = ({ key }) => {
            if (key === targetKey) {
                setKeyPressed(false);
            }
        };
        document.addEventListener('keydown', downHandler);
        document.addEventListener('keyup', upHandler);
        return () => {
            document.removeEventListener('keydown', downHandler);
            document.removeEventListener('keyup', upHandler);
        };
    }, [targetKey]);
    return keyPressed;
};
export default useKeyPress;
