import { useTranslation, useSearchByShelfNums } from 'hooks';
import { TagInput } from 'components/common';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';

const SearchIcon = () => (
  <MagnifyingGlassIcon className="w-5 h-5 text-white" aria-label="search icon" />
);

export function SearchByShelfNumsBar() {
  const { t } = useTranslation();
  const { shelfNums, updateShelfNums } = useSearchByShelfNums();

  return (
    <TagInput
      prefix={<SearchIcon />}
      ariaLabel="search-by-shelfnums"
      placeholder={t('placeholder.search-by-shelf-nums')}
      maxLength={4}
      value={shelfNums}
      onChange={updateShelfNums}
      className="max-w-sm"
      maxTagCount={5}
    />
  );
}
