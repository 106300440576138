import { isValidShelfNum, isValidSKU, toArray } from 'lib/helpers';
import { useURLQueryParams } from './useURLQueryParams';
export interface useSearchResult {
  skus: string[];
  updateSKUs: (values: string[]) => void;
}

export function useSearch(): useSearchResult {
  const { queryParams, updateValues } = useURLQueryParams();

  const skus = toArray(queryParams.skus).filter(isValidSKU);

  const updateSKUs = (values: string[]) => {
    updateValues({ skus: values.filter(isValidSKU) });
  };

  return { skus, updateSKUs };
}

export interface useSearchByShelfNumsResult {
  shelfNums: string[];
  updateShelfNums: (values: string[]) => void;
}

export function useSearchByShelfNums(): useSearchByShelfNumsResult {
  const { queryParams, updateValues } = useURLQueryParams();

  const shelfNums = toArray(queryParams.shelfNums).filter(isValidShelfNum);

  const updateShelfNums = (values: string[]) => {
    updateValues({ shelfNums: values.filter(isValidShelfNum) });
  };

  return { shelfNums, updateShelfNums };
}
