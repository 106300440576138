import { useMemo } from 'react';
import { Dropdown } from '@douyinfe/semi-ui';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { useTranslation, useTableRowActions } from 'hooks';
import { canInvalidateBundles, canUpdateInventoryLevels } from 'lib/appPermissions';
import { Inventory } from 'graphql/__generated__/schemas';

type ActionItem = {
  label: string;
  disabled?: boolean;
  onClick?: () => void;
};

interface RowActionsProps {
  rowData: Inventory;
}

export default function RowActions({ rowData }: RowActionsProps) {
  const { t } = useTranslation();
  const { onSelectAction } = useTableRowActions();

  const actionsList = useMemo((): ActionItem[] => {
    const disableUpdateAction = rowData.isBundle || !canUpdateInventoryLevels();
    const disableSyncAction = !canInvalidateBundles();

    const onClickUpdateAction = () => onSelectAction({ row: rowData, action: 'update' });
    const onClickSyncAction = () => onSelectAction({ row: rowData, action: 'sync' });

    return [
      {
        label: t('action.update'),
        disabled: disableUpdateAction,
        onClick: disableUpdateAction ? undefined : onClickUpdateAction,
      },
      {
        label: t('action.sync'),
        disabled: disableSyncAction,
        onClick: disableSyncAction ? undefined : onClickSyncAction,
      },
    ];
  }, [t, rowData, onSelectAction]);

  return (
    <Dropdown
      trigger="click"
      clickToHide
      stopPropagation
      position="bottom"
      render={
        <Dropdown.Menu>
          {actionsList.map(({ label, disabled, onClick }) => (
            <Dropdown.Item key={label} disabled={disabled} onClick={onClick}>
              {label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      }
    >
      <EllipsisVerticalIcon
        className="m-auto w-5 h-5  cursor-pointer focus:outline-none"
        aria-label={`row-actions-for-sku-${rowData.sku}`}
      />
    </Dropdown>
  );
}
