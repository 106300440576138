import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  type ColumnDef,
  type TableOptions,
  RowSelectionState,
} from '@tanstack/react-table';
import { Pagination } from '@hubportal/components';
import { useFilters, useTranslation } from 'hooks';
import { PAGINATION } from 'lib/constants';
import type { PaginationControls } from 'types';
import { useEffect, useState } from 'react';
import { useBulkUpdateStock } from 'stores';

const DEFAULT_COL_WIDTH = 150;

export interface TablePaginationProps extends PaginationControls {
  pageCount: number;
  totalCount: number;
}

export interface TableProps<TData> {
  data: TData[];
  colDefs: ColumnDef<TData>[];
  paginationProps: TablePaginationProps;
  ariaLabel?: string;
  className?: string;
  rowSelectionState?: TableOptions<TData>['state']['rowSelection'];
  setRowSelectionState?: TableOptions<TData>['onRowSelectionChange'];
}

export function Table<
  TData extends {
    id: string;
    sku: string;
    isBundle?: boolean;
  }
>({ data, colDefs, paginationProps, ariaLabel }: TableProps<TData>) {
  const { t } = useTranslation();
  const { hub } = useFilters();
  const { isFormVisible, selectedSKUsById, setSelectedSKUsById } = useBulkUpdateStock();
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const table = useReactTable({
    data,
    columns: colDefs,
    getCoreRowModel: getCoreRowModel(),
    enableColumnResizing: true,
    manualPagination: true,
    enableRowSelection: (row) => !row.original.isBundle,
    getRowId: (row) => row.id,
    state: {
      rowSelection,
    },
    onRowSelectionChange: setRowSelection,
  });

  useEffect(() => {
    if (!isFormVisible && hub) {
      const newRowSelection = Object.keys(selectedSKUsById).reduce((result, key) => {
        result[key] = true;
        return result;
      }, {});
      setRowSelection(newRowSelection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormVisible, hub]);

  useEffect(() => {
    const selected = Object.keys(rowSelection).reduce((result, key) => {
      const row = table.getSelectedRowModel().rowsById[key];
      result[key] = row?.original.sku || selectedSKUsById[key];
      return result;
    }, {});

    setSelectedSKUsById(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection]);

  const { pageCount, totalCount, pageIndex, pageSize, setPage, setPageSize } = paginationProps;

  if (data.length === 0)
    return (
      <div className="h-4/5 flex justify-center items-center text-white">
        {t('info.no-inventories-available')}
      </div>
    );

  return (
    <div className="flex flex-col gap-4">
      <div className="h-[calc(100vh-260px)] overflow-x-hidden overflow-y-scroll">
        <table
          className="table-fixed w-full border-separate border-spacing-x-0 border-spacing-y-1 text-white"
          aria-label={ariaLabel}
        >
          <thead data-testid="table-head" className="bg-flinkGray-medium sticky top-0 z-10">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const headerLabel = flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  );
                  return (
                    <th
                      key={header.id}
                      className="py-1"
                      style={{
                        width:
                          header.getSize() !== DEFAULT_COL_WIDTH ? header.getSize() : undefined,
                      }}
                    >
                      {headerLabel ? (
                        <div className="font-medium text-flinkGray-light bg-flinkGray-dark rounded px-2 py-1.5 w-fit mx-auto">
                          {headerLabel}
                        </div>
                      ) : null}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          {data.length > 0 && (
            <tbody data-testid="table-body">
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className="bg-flinkGray h-16">
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id} className="text-center first:rounded-l-lg last:rounded-r-lg">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      <div data-testid="pagination">
        <Pagination
          labels={{ show: t('pagination.show') }}
          pageSizeOptions={PAGINATION.ALLOWED_PAGE_SIZES}
          pageIndex={pageIndex - 1}
          pageSize={pageSize}
          pageCount={pageCount}
          totalCount={totalCount}
          goToPage={(pageIndex) => setPage(pageIndex + 1)}
          setPageSize={setPageSize}
        />
      </div>
    </div>
  );
}
