import { Theme, ToastOptions, ToastPosition, toast } from 'react-toastify';

const TOAST_DEFAULT_OPTIONS = {
  position: 'bottom-center' as ToastPosition,
  autoClose: 1000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored' as Theme,
};

export const notifyError = (message?: string, options?: ToastOptions) => {
  return toast.error(message || 'Error', { ...TOAST_DEFAULT_OPTIONS, ...options });
};

export const notifySuccess = (message?: string, options?: ToastOptions) => {
  return toast.success(message || 'Success', { ...TOAST_DEFAULT_OPTIONS, ...options });
};
