import { useSyncBundle, useTranslation } from 'hooks';
import { Spinner, Modal } from 'components/common';
import { Icon } from '@hubportal/components';

type BundleSyncModalProps = {
  isOpen: boolean;
  hubSlug: string;
  sku: string;
  onClose: () => void;
};
export const BundleSyncModal = ({ isOpen, hubSlug, sku, onClose }: BundleSyncModalProps) => {
  const { t } = useTranslation();
  const { syncBundle, isLoading } = useSyncBundle({ sku, hubSlug, onSuccess: onClose });

  const handleUpdate = () => syncBundle();

  return (
    <Modal
      isOpen={isOpen}
      onClickOutside={onClose}
      customClass="w-2/5 text-start"
      title={t('modal.bundle-sync.title')}
    >
      <>
        <p className="py-2 text-white">{t('modal.bundle-sync.sub-title')}</p>
        <p className="text-white text-lg">
          {t('form-field.bundle-sku')}
          <span className="underline underline-offset-8 p-2">{sku}</span>
        </p>
        <p className="text-sm py-2 flex items-center gap-1 text-white">
          <Icon type="caution" size="small" />
          {t('info.caution')}
        </p>
        <div className="flex justify-between gap-1 mt-1">
          <button
            className="text-white bg-flinkGray-dark w-56 leading-none p-4 rounded fontFamily-sans text-lg font-extrabold h-13"
            onClick={onClose}
          >
            {t('action.cancel')}
          </button>
          <button
            className="flex items-center justify-center text-white bg-red w-56 leading-none p-4 rounded fontFamily-sans text-lg font-extrabold h-13 disabled:bg-flinkGray-light"
            onClick={handleUpdate}
            disabled={!sku}
          >
            {isLoading ? <Spinner size={5} /> : t('action.sync')}
          </button>
        </div>
      </>
    </Modal>
  );
};
