import { useTableRowActions } from 'hooks';
import { UpdateInventorySidebar } from 'components/UpdateInventorySidebar';
import { BundleSyncModal } from 'components/BundleSyncModal';

export function RowActionsOverlays({ refetchInventoryList }: { refetchInventoryList: () => void }) {
  const { action, row, onActionCompleted } = useTableRowActions();

  return (
    <>
      <UpdateInventorySidebar
        visible={action === 'update'}
        rowData={row}
        onClose={onActionCompleted}
        refetchInventoryList={refetchInventoryList}
      />

      {row && (
        <BundleSyncModal
          isOpen={action === 'sync'}
          hubSlug={row.hubSlug}
          sku={row.sku}
          onClose={onActionCompleted}
        />
      )}
    </>
  );
}
