import { ReactElement, useEffect, useRef } from 'react';
import { useKeyPress, useOnClickOutside } from '@flpkg/hooks';

type ModalProps = {
  title?: string;
  isOpen: boolean;
  onClickOutside: () => void;
  customClass?: string;
  children: ReactElement;
};

export const Modal = ({
  title,
  isOpen = false,
  onClickOutside,
  customClass,
  children,
}: ModalProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const escape = useKeyPress('Escape');

  useEffect(() => {
    if (escape && isOpen) {
      onClickOutside();
    }
  }, [escape, isOpen, onClickOutside]);

  useOnClickOutside(ref, null, () => {
    if (isOpen) {
      onClickOutside();
    }
  });

  const additionalClass = customClass ? customClass : 'h-auto overflow-hidden w-132';

  if (!isOpen) return null;

  return (
    <div
      className="w-full h-full flex fixed bg-background box-border flex-row items-center justify-center overflow-hidden top-0 left-0 z-20 transition-all ease-in-out duration-2000 transform"
      role="presentation"
      onClick={onClickOutside}
      ref={ref}
    >
      <div
        className={`bg-flinkGray-medium p-8 rounded-lg ${additionalClass}`}
        role="presentation"
        onClick={(event) => event.stopPropagation()}
      >
        <p className="text-white font-medium text-2xl">{title}</p>
        {children}
      </div>
    </div>
  );
};
