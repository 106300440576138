import { DEFAULT_UPDATE_INVENTORY_REASON, REASONS } from 'lib/constants';

type ReasonKey = keyof typeof REASONS;
type ReasonValue = (typeof REASONS)[ReasonKey];

export type SelectOption = {
  id: ReasonKey;
  label: ReasonValue;
};

export const DEFAULT_UPDATE_INVENTORY_REASON_OPTION: SelectOption = {
  id: DEFAULT_UPDATE_INVENTORY_REASON,
  label: REASONS[DEFAULT_UPDATE_INVENTORY_REASON],
};

export const getReasonsDropdownOptions = (): SelectOption[] =>
  Object.entries(REASONS).map(([reasonId, translationKey]) => ({
    id: reasonId as ReasonKey,
    label: translationKey as ReasonValue,
  }));
