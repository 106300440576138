import { useCallback } from 'react';
import { notifyError, notifySuccess } from 'lib/toast';
import { useInventoryManagerUpdateStockLevelMutation } from 'graphql/__generated__/hooks';
import sdk from '@hubportal/sdk';
import {
  InventoryManagerUpdateStockLevelInput,
  UpdateStockLevelInputBody,
} from 'graphql/__generated__/schemas';

type APIBody = UpdateStockLevelInputBody;

type UpdateInventoryEntryFn = (params: Pick<APIBody, 'amount' | 'reason'>) => Promise<void>;

interface UseUpdateInventoryEntryParams
  extends Pick<InventoryManagerUpdateStockLevelInput, 'sku' | 'hubId'> {
  onSuccess: () => void;
}

interface UseUpdateInventoryEntryResult {
  updateInventoryEntry: UpdateInventoryEntryFn;
  isLoading: boolean;
}

type UseUpdateInventoryEntryHook = (
  params: UseUpdateInventoryEntryParams
) => UseUpdateInventoryEntryResult;

export const useUpdateInventoryEntry: UseUpdateInventoryEntryHook = ({ sku, hubId, onSuccess }) => {
  const [inventoryManagerUpdateStockLevelMutation, { loading }] =
    useInventoryManagerUpdateStockLevelMutation({
      onCompleted() {
        notifySuccess();
        onSuccess();
      },
      onError(error) {
        notifyError(error.message);
        sdk.reportError(error, '@flapp/inventory-management');
      },
    });

  const updateInventoryEntry: UpdateInventoryEntryFn = useCallback(
    async ({ amount, reason }) => {
      await inventoryManagerUpdateStockLevelMutation({
        variables: {
          input: {
            sku,
            hubId,
            body: { actor: { id: sdk.getUserProfile().email }, amount, reason },
          },
        },
      });
    },
    [inventoryManagerUpdateStockLevelMutation, sku, hubId]
  );

  return { updateInventoryEntry, isLoading: loading };
};
