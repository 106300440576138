import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Checkbox } from 'components/common';
import { InventoryTypeBadge } from 'components/InventoryTypeBadge';
import { canSelectTableRows } from 'lib/appPermissions';
import { getFormattedDateAndTime } from 'lib/helpers';
import type { TFunction } from 'hooks';
import { Inventory } from 'graphql/__generated__/schemas';
import RowActions from './RowActions';

const columnHelper = createColumnHelper<Inventory>();
const columnSelect = canSelectTableRows()
  ? [
      columnHelper.display({
        id: 'select',
        size: 80,
        header: ({ table }) => (
          <Checkbox
            aria-label="Select all rows"
            {...{
              checked: table.getIsAllPageRowsSelected(),
              indeterminate: table.getIsSomePageRowsSelected(),
              onChange: table.getToggleAllPageRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            className="inline-block"
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        ),
      }),
    ]
  : [];

export const getColumnDefs = ({
  t,
  enabledAction,
}: {
  t: TFunction;
  enabledAction: boolean;
}): ColumnDef<Inventory>[] => [
  ...columnSelect,
  columnHelper.accessor('isBundle', {
    header: t('attribute.type'),
    cell: (info) => {
      const isBundle = info.getValue();
      return <InventoryTypeBadge isBundle={isBundle} />;
    },
  }),
  columnHelper.accessor('sku', {
    header: t('attribute.sku'),
  }),
  columnHelper.accessor('productName', {
    header: t('attribute.productName'),
    size: 500,
  }),
  columnHelper.accessor('shelfNumber', {
    header: t('attribute.shelfNumber'),
  }),
  columnHelper.accessor('hubSlug', {
    header: t('attribute.hub-slug'),
  }),
  columnHelper.accessor('quantity', {
    header: t('attribute.quantity'),
  }),
  columnHelper.accessor('updatedAt', {
    header: t('attribute.last-update'),
    cell: (info) => {
      const updatedAt = info.getValue();
      const date = getFormattedDateAndTime(updatedAt, 'dd.MM.yyyy');
      const time = getFormattedDateAndTime(updatedAt, 'HH:mm');
      return (
        <span>
          {date} <br /> {time}
        </span>
      );
    },
  }),
  ...(enabledAction
    ? [
        columnHelper.display({
          header: t('general.actions'),
          id: 'actions',
          size: 100,
          cell: ({ row }) => <RowActions rowData={row.original} />,
        }),
      ]
    : []),
];
