import { useCallback } from 'react';
import useUrlState, { type Options as ParserOptions } from '@ahooksjs/use-url-state';
import { DEFAULT_SELECTED_HUB_SLUG, PAGINATION } from 'lib/constants';
import { QueryParams } from 'types';

const parsingOptions: ParserOptions = {
  parseOptions: { arrayFormat: 'none' },
  stringifyOptions: { arrayFormat: 'none' },
};

export const initialState: QueryParams = {
  pageIndex: PAGINATION.FIRST_PAGE_NUMBER,
  pageSize: PAGINATION.DEFAULT_PAGE_SIZE,
  skus: [],
  shelfNums: [],
  hub: DEFAULT_SELECTED_HUB_SLUG,
};

export interface useURLQueryParamsResult {
  queryParams: Partial<QueryParams>;
  updateValues: (values: Partial<QueryParams>) => void;
}

export function useURLQueryParams(): useURLQueryParamsResult {
  const [queryParams, setQueryParams] = useUrlState(initialState, parsingOptions);

  const updateValues = useCallback(
    (overriddenQueryParams: Partial<QueryParams>) => {
      const { pageIndex: passedPageIndex, ...otherQueryParams } = overriddenQueryParams;

      const pageIndex =
        !passedPageIndex || passedPageIndex <= PAGINATION.FIRST_PAGE_NUMBER
          ? undefined
          : passedPageIndex;

      // useUrlState hook does partial updates; meaning that passed values will not reset other values in the state
      // In our case, we want to reset the page index to the default value (by setting it to undefined) whenever any other value (e.g. search/filter) changes
      // so that we avoid situations where current page index is greater than the number of pages after applying search/filter/etc.
      setQueryParams({ pageIndex, ...otherQueryParams });
    },
    [setQueryParams]
  );

  return { queryParams, updateValues };
}
