import { useGetAllHubsQuery } from 'graphql/__generated__/hooks';
import { HubSummary } from 'graphql/__generated__/schemas';
import sdk from '@hubportal/sdk';

interface useHubsResult {
  hubs: undefined | HubSummary[];
  error?: string;
  isLoading?: boolean;
}

export function useHubs(): useHubsResult {
  const { data, error, loading } = useGetAllHubsQuery({
    onError(error) {
      sdk.reportError(new Error(error?.message), '@flapp/inventory-management');
    },
  });
  return { hubs: data?.getAllHubs, error: error?.message, isLoading: loading };
}
