import { notifyError, notifySuccess } from 'lib/toast';
import { useUpdateBundleCacheMutation } from 'graphql/__generated__/hooks';

interface UseSyncBundleParams {
  sku: string;
  hubSlug: string;
  onSuccess?: () => void;
}

interface UseSyncBundleResult {
  syncBundle: () => void;
  isLoading: boolean;
}

type UseSyncBundleHook = (params: UseSyncBundleParams) => UseSyncBundleResult;

export const useSyncBundle: UseSyncBundleHook = ({ sku, hubSlug, onSuccess }) => {
  const [updateBundleCacheMutation, { loading }] = useUpdateBundleCacheMutation({
    variables: {
      input: { hubSlug, sku },
    },
    onCompleted() {
      notifySuccess();
      onSuccess();
    },
    onError(error) {
      notifyError(error.message);
    },
  });

  return {
    isLoading: loading,
    syncBundle: updateBundleCacheMutation,
  };
};
