import { useTranslation, useSearch } from 'hooks';
import { TagInput } from 'components/common';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';

const SearchIcon = () => (
  <MagnifyingGlassIcon className="w-5 h-5 text-white" aria-label="search icon" />
);

export function Searchbar() {
  const { t } = useTranslation();
  const { skus, updateSKUs } = useSearch();

  return (
    <TagInput
      prefix={<SearchIcon />}
      ariaLabel="search-by-skus"
      placeholder={t('placeholder.search')}
      maxLength={9}
      value={skus}
      onChange={updateSKUs}
      className="max-w-screen-sm"
      maxTagCount={5}
    />
  );
}
