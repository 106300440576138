import { useTranslation, usePagination } from 'hooks';
import { Table, type TablePaginationProps } from 'components/common';
import { getColumnDefs } from './columnsDefinitions';
import { ListInventory } from 'graphql/__generated__/schemas';
import { RowActionsOverlays } from 'components/RowActionsOverlays';
import { useInventoryResetStatus } from 'stores';

export interface InventoryEntriesProps {
  data: ListInventory;
  refetchInventoryList: () => void;
}

export const InventoryEntries: React.FC<InventoryEntriesProps> = ({
  data,
  refetchInventoryList,
}) => {
  const { t } = useTranslation();
  const paginationControls = usePagination();
  const tablePaginationProps: TablePaginationProps = {
    ...paginationControls,
    pageCount: data?.pagination?.totalPages ?? 0,
    totalCount: data?.pagination?.totalItems ?? 0,
  };
  const { status } = useInventoryResetStatus();

  return (
    <>
      <Table
        ariaLabel="Inventory Entries"
        data={data.inventories}
        colDefs={getColumnDefs({ t, enabledAction: !status })}
        paginationProps={tablePaginationProps}
      />
      <RowActionsOverlays refetchInventoryList={refetchInventoryList} />
    </>
  );
};
