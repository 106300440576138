import * as Types from './operations';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const UpdateBundleCacheDocument = gql`
    mutation updateBundleCache($input: UpdateBundleCacheInput!) {
  updateBundleCache(input: $input) {
    status
  }
}
    `;
export type UpdateBundleCacheMutationFn = Apollo.MutationFunction<Types.UpdateBundleCacheMutation, Types.UpdateBundleCacheMutationVariables>;

/**
 * __useUpdateBundleCacheMutation__
 *
 * To run a mutation, you first call `useUpdateBundleCacheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBundleCacheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBundleCacheMutation, { data, loading, error }] = useUpdateBundleCacheMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBundleCacheMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateBundleCacheMutation, Types.UpdateBundleCacheMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateBundleCacheMutation, Types.UpdateBundleCacheMutationVariables>(UpdateBundleCacheDocument, options);
      }
export type UpdateBundleCacheMutationHookResult = ReturnType<typeof useUpdateBundleCacheMutation>;
export type UpdateBundleCacheMutationResult = Apollo.MutationResult<Types.UpdateBundleCacheMutation>;
export type UpdateBundleCacheMutationOptions = Apollo.BaseMutationOptions<Types.UpdateBundleCacheMutation, Types.UpdateBundleCacheMutationVariables>;
export const InventoryManagerUpdateStockLevelDocument = gql`
    mutation inventoryManagerUpdateStockLevel($input: InventoryManagerUpdateStockLevelInput!) {
  inventoryManagerUpdateStockLevel(input: $input) {
    inventory {
      availableQuantity
      hubId
      lastModifiedAt
      shelfNumber
      sku
      version
    }
  }
}
    `;
export type InventoryManagerUpdateStockLevelMutationFn = Apollo.MutationFunction<Types.InventoryManagerUpdateStockLevelMutation, Types.InventoryManagerUpdateStockLevelMutationVariables>;

/**
 * __useInventoryManagerUpdateStockLevelMutation__
 *
 * To run a mutation, you first call `useInventoryManagerUpdateStockLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInventoryManagerUpdateStockLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inventoryManagerUpdateStockLevelMutation, { data, loading, error }] = useInventoryManagerUpdateStockLevelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInventoryManagerUpdateStockLevelMutation(baseOptions?: Apollo.MutationHookOptions<Types.InventoryManagerUpdateStockLevelMutation, Types.InventoryManagerUpdateStockLevelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InventoryManagerUpdateStockLevelMutation, Types.InventoryManagerUpdateStockLevelMutationVariables>(InventoryManagerUpdateStockLevelDocument, options);
      }
export type InventoryManagerUpdateStockLevelMutationHookResult = ReturnType<typeof useInventoryManagerUpdateStockLevelMutation>;
export type InventoryManagerUpdateStockLevelMutationResult = Apollo.MutationResult<Types.InventoryManagerUpdateStockLevelMutation>;
export type InventoryManagerUpdateStockLevelMutationOptions = Apollo.BaseMutationOptions<Types.InventoryManagerUpdateStockLevelMutation, Types.InventoryManagerUpdateStockLevelMutationVariables>;
export const InventoryManagerBulkUpdateStockLevelDocument = gql`
    mutation inventoryManagerBulkUpdateStockLevel($input: BulkUpdateStockLevelInput!) {
  inventoryManagerBulkUpdateStockLevel(input: $input) {
    success
  }
}
    `;
export type InventoryManagerBulkUpdateStockLevelMutationFn = Apollo.MutationFunction<Types.InventoryManagerBulkUpdateStockLevelMutation, Types.InventoryManagerBulkUpdateStockLevelMutationVariables>;

/**
 * __useInventoryManagerBulkUpdateStockLevelMutation__
 *
 * To run a mutation, you first call `useInventoryManagerBulkUpdateStockLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInventoryManagerBulkUpdateStockLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inventoryManagerBulkUpdateStockLevelMutation, { data, loading, error }] = useInventoryManagerBulkUpdateStockLevelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInventoryManagerBulkUpdateStockLevelMutation(baseOptions?: Apollo.MutationHookOptions<Types.InventoryManagerBulkUpdateStockLevelMutation, Types.InventoryManagerBulkUpdateStockLevelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InventoryManagerBulkUpdateStockLevelMutation, Types.InventoryManagerBulkUpdateStockLevelMutationVariables>(InventoryManagerBulkUpdateStockLevelDocument, options);
      }
export type InventoryManagerBulkUpdateStockLevelMutationHookResult = ReturnType<typeof useInventoryManagerBulkUpdateStockLevelMutation>;
export type InventoryManagerBulkUpdateStockLevelMutationResult = Apollo.MutationResult<Types.InventoryManagerBulkUpdateStockLevelMutation>;
export type InventoryManagerBulkUpdateStockLevelMutationOptions = Apollo.BaseMutationOptions<Types.InventoryManagerBulkUpdateStockLevelMutation, Types.InventoryManagerBulkUpdateStockLevelMutationVariables>;
export const InventoryManagerHubResetDocument = gql`
    mutation inventoryManagerHubReset($input: HubResetInput!) {
  inventoryManagerHubReset(input: $input) {
    status
  }
}
    `;
export type InventoryManagerHubResetMutationFn = Apollo.MutationFunction<Types.InventoryManagerHubResetMutation, Types.InventoryManagerHubResetMutationVariables>;

/**
 * __useInventoryManagerHubResetMutation__
 *
 * To run a mutation, you first call `useInventoryManagerHubResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInventoryManagerHubResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inventoryManagerHubResetMutation, { data, loading, error }] = useInventoryManagerHubResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInventoryManagerHubResetMutation(baseOptions?: Apollo.MutationHookOptions<Types.InventoryManagerHubResetMutation, Types.InventoryManagerHubResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InventoryManagerHubResetMutation, Types.InventoryManagerHubResetMutationVariables>(InventoryManagerHubResetDocument, options);
      }
export type InventoryManagerHubResetMutationHookResult = ReturnType<typeof useInventoryManagerHubResetMutation>;
export type InventoryManagerHubResetMutationResult = Apollo.MutationResult<Types.InventoryManagerHubResetMutation>;
export type InventoryManagerHubResetMutationOptions = Apollo.BaseMutationOptions<Types.InventoryManagerHubResetMutation, Types.InventoryManagerHubResetMutationVariables>;
export const GetAllHubsDocument = gql`
    query getAllHubs {
  getAllHubs {
    slug
  }
}
    `;

/**
 * __useGetAllHubsQuery__
 *
 * To run a query within a React component, call `useGetAllHubsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllHubsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllHubsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllHubsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAllHubsQuery, Types.GetAllHubsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllHubsQuery, Types.GetAllHubsQueryVariables>(GetAllHubsDocument, options);
      }
export function useGetAllHubsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllHubsQuery, Types.GetAllHubsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllHubsQuery, Types.GetAllHubsQueryVariables>(GetAllHubsDocument, options);
        }
export type GetAllHubsQueryHookResult = ReturnType<typeof useGetAllHubsQuery>;
export type GetAllHubsLazyQueryHookResult = ReturnType<typeof useGetAllHubsLazyQuery>;
export type GetAllHubsQueryResult = Apollo.QueryResult<Types.GetAllHubsQuery, Types.GetAllHubsQueryVariables>;
export const GetInventoryManagerListInventoryDocument = gql`
    query getInventoryManagerListInventory($input: GetListInventoryInput!) {
  getInventoryManagerListInventory(input: $input) {
    pagination {
      pageNumber
      pageSize
      totalItems
      totalPages
    }
    inventories {
      productName
      hubSlug
      id
      quantity
      shelfNumber
      sku
      updatedAt
      isBundle
    }
  }
}
    `;

/**
 * __useGetInventoryManagerListInventoryQuery__
 *
 * To run a query within a React component, call `useGetInventoryManagerListInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInventoryManagerListInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInventoryManagerListInventoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetInventoryManagerListInventoryQuery(baseOptions: Apollo.QueryHookOptions<Types.GetInventoryManagerListInventoryQuery, Types.GetInventoryManagerListInventoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetInventoryManagerListInventoryQuery, Types.GetInventoryManagerListInventoryQueryVariables>(GetInventoryManagerListInventoryDocument, options);
      }
export function useGetInventoryManagerListInventoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetInventoryManagerListInventoryQuery, Types.GetInventoryManagerListInventoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetInventoryManagerListInventoryQuery, Types.GetInventoryManagerListInventoryQueryVariables>(GetInventoryManagerListInventoryDocument, options);
        }
export type GetInventoryManagerListInventoryQueryHookResult = ReturnType<typeof useGetInventoryManagerListInventoryQuery>;
export type GetInventoryManagerListInventoryLazyQueryHookResult = ReturnType<typeof useGetInventoryManagerListInventoryLazyQuery>;
export type GetInventoryManagerListInventoryQueryResult = Apollo.QueryResult<Types.GetInventoryManagerListInventoryQuery, Types.GetInventoryManagerListInventoryQueryVariables>;
export const GetHubInventoryResetStatusDocument = gql`
    query getHubInventoryResetStatus($input: GetHubInventoryResetStatusInput!) {
  getHubInventoryResetStatus(input: $input) {
    status
    updatedAt
  }
}
    `;

/**
 * __useGetHubInventoryResetStatusQuery__
 *
 * To run a query within a React component, call `useGetHubInventoryResetStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHubInventoryResetStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHubInventoryResetStatusQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetHubInventoryResetStatusQuery(baseOptions: Apollo.QueryHookOptions<Types.GetHubInventoryResetStatusQuery, Types.GetHubInventoryResetStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetHubInventoryResetStatusQuery, Types.GetHubInventoryResetStatusQueryVariables>(GetHubInventoryResetStatusDocument, options);
      }
export function useGetHubInventoryResetStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetHubInventoryResetStatusQuery, Types.GetHubInventoryResetStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetHubInventoryResetStatusQuery, Types.GetHubInventoryResetStatusQueryVariables>(GetHubInventoryResetStatusDocument, options);
        }
export type GetHubInventoryResetStatusQueryHookResult = ReturnType<typeof useGetHubInventoryResetStatusQuery>;
export type GetHubInventoryResetStatusLazyQueryHookResult = ReturnType<typeof useGetHubInventoryResetStatusLazyQuery>;
export type GetHubInventoryResetStatusQueryResult = Apollo.QueryResult<Types.GetHubInventoryResetStatusQuery, Types.GetHubInventoryResetStatusQueryVariables>;