import { useTranslation } from 'hooks';
import { Sidebar } from 'components/common';
import { UpdateInventoryForm } from './UpdateInventoryForm';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Inventory } from 'graphql/__generated__/schemas';

export interface UpdateInventorySidebarProps {
  rowData: Inventory | null;
  visible: boolean;
  onClose: () => void;
  refetchInventoryList: () => void;
}

export function UpdateInventorySidebar({
  rowData,
  visible,
  onClose,
  refetchInventoryList,
}: UpdateInventorySidebarProps) {
  const { t } = useTranslation();

  const onSuccess = () => {
    refetchInventoryList();
    onClose();
  };

  return (
    <Sidebar visible={visible} title={t('sidebar.update-inventory.title')} onClose={onClose}>
      <ErrorBoundary>
        {rowData && <UpdateInventoryForm inventoryEntry={rowData} onSuccess={onSuccess} />}
      </ErrorBoundary>
    </Sidebar>
  );
}
