import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { InventoryEntriesPage, NotFoundPage } from 'pages';
import { Header } from '@hubportal/components';
import { APP_NAME, isDevelopmentEnv } from 'lib/constants/appConfig';
import { ROUTER_BASENAME, HOMEPAGE_PATH } from 'lib/constants/routes';

import './tailwind.scss';
import 'react-toastify/dist/ReactToastify.css';
import '@hubportal/components/index.css';
import { CustomApolloProvider } from 'providers/CustomApolloProvider';
import { HubReset } from 'components/HubReset';
import { canHubInventoryReset } from 'lib/appPermissions';

const rootNodeId = `${APP_NAME}-app`;

if (isDevelopmentEnv) {
  import('./testUtils/msw/browser').then(({ worker }) => {
    worker.start({
      onUnhandledRequest: 'bypass',
    });
  });
}

export function Root() {
  return (
    <>
      <Header title="Inventory Management">{canHubInventoryReset() && <HubReset />}</Header>
      <Routes>
        <Route path={HOMEPAGE_PATH} element={<InventoryEntriesPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}

export function App() {
  return (
    <div id={rootNodeId} className="h-screen">
      <CustomApolloProvider>
        <BrowserRouter basename={ROUTER_BASENAME}>
          <Root />
        </BrowserRouter>
      </CustomApolloProvider>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        limit={5}
        newestOnTop
        hideProgressBar
        draggable={false}
        theme="colored"
        bodyStyle={{ zIndex: 999999 }}
      />
    </div>
  );
}
