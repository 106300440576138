import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { enableDevTool } from 'lib/constants/flag';
import { InventoryResetStatusStore, InventoryResetStatuseWithMethods } from './types';

const initialState: InventoryResetStatusStore = {
  status: null,
};

export const useInventoryResetStatus = create<InventoryResetStatuseWithMethods>()(
  devtools(
    (set) => ({
      ...initialState,
      setInventoryResetStatus(status) {
        set((currentState) => ({
          ...currentState,
          status,
        }));
      },
    }),
    { name: 'useInventoryResetStatus', enabled: enableDevTool }
  )
);
