import React, { PropsWithChildren } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'hooks';
import { toast } from 'react-toastify';
import { isDevelopmentEnv, isTestEnv } from 'lib/constants/appConfig';

export type ErrorBoundaryProps = PropsWithChildren<{
  onError?: (error: Error, info: React.ErrorInfo) => void;
  onReset?: () => void;
}>;

export const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ onReset, onError, children }) => {
  const { t } = useTranslation();

  return (
    <ReactErrorBoundary
      onError={(error: Error, info: React.ErrorInfo) => {
        (isDevelopmentEnv || isTestEnv) && console.error(error);
        toast.error(t('info.generic-error'));
        onError?.(error, info);
      }}
      fallbackRender={({ error, resetErrorBoundary }) => {
        const runtimeErrorMessage = error.message;
        return (
          <div className="h-full flex justify-center items-center">
            <div
              className="flex flex-col items-start bg-red-100 border-l-4 border-red-500 text-red-700 p-4"
              role="alert"
            >
              <p className="font-bold">{t('info.generic-error')}</p>
              {isDevelopmentEnv && (
                <div className="mt-4">
                  <pre className="whitespace-pre-wrap text-black">{runtimeErrorMessage}</pre>
                </div>
              )}

              <button
                className="bg-transparent font-bold self-center text-sm text-red border border-red mt-8 py-2 px-4 rounded"
                onClick={resetErrorBoundary}
              >
                {t('action.try-again')}
              </button>
            </div>
          </div>
        );
      }}
      onReset={onReset}
    >
      {children}
    </ReactErrorBoundary>
  );
};
