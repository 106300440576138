import { Select, SelectItem, type SelectProps } from '@hubportal/components';
import { HubSummary } from 'graphql/__generated__/schemas';
import { useTranslation, useHubs, useFilters } from 'hooks';
import { useBulkUpdateStock } from 'stores';

export function getSelectedHubOption(
  hubs: HubSummary[],
  hubSlug: string
): SelectProps['value'] | undefined {
  const matchingHub = hubs?.find(({ slug }) => slug === hubSlug);

  if (!matchingHub) return;

  return {
    id: matchingHub.slug,
    label: matchingHub.slug,
  };
}

export function HubsFilter() {
  const { t } = useTranslation();
  const { hubs, error, isLoading } = useHubs();
  const { reset } = useBulkUpdateStock();
  const { hub, updateHub } = useFilters();
  const handleOnChange = ({ id }) => {
    updateHub(id);
    reset();
  };
  if (isLoading) {
    return <Select label={t('filters.hub')} placeholder={t('info.loading')} onChange={() => {}} />;
  }
  const selectedHub = getSelectedHubOption(hubs, hub);
  const props = { id: 'hub-select' };
  return (
    <Select
      searchable
      placeholder={t('filters.hub')}
      value={selectedHub}
      onChange={handleOnChange}
      disabled={!!error}
      {...props}
    >
      {hubs &&
        hubs.map(({ slug }) => (
          <SelectItem key={slug} id={slug} label={slug}>
            {slug}
          </SelectItem>
        ))}
    </Select>
  );
}
