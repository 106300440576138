import { Checkbox as SemiDesignCheckbox } from '@douyinfe/semi-ui';
import { type CheckboxProps as SemiDesignCheckboxProps } from '@douyinfe/semi-ui/lib/es/checkbox';
import './Checkbox.scss';

export interface CheckboxProps extends SemiDesignCheckboxProps {
  ariaLabel?: string;
}

export function Checkbox({ ariaLabel, ...overriddenProps }: CheckboxProps) {
  return <SemiDesignCheckbox aria-label={ariaLabel} {...overriddenProps} />;
}
